//
import { VwLayoutAlert } from 'vuetify-wrappers/v2'

//
export default {
  name: 'app-layout-alert',
  extends: VwLayoutAlert,
  props: {

    /**
     * 
     */
    alertProps: {
      type: Object,
      default: () => ({
        class: 'mb-0',
        prominent: true,
      })
    },

    /**
     * 
     */
    rowProps: {
      type: Object,
      default: () => ({
        noGutters: true,
      })
    },

    /**
     * 
     */
    subtitleProps: {
      type: Object,
      default: () => ({
        class: 'subtitle-1',
      })
    },

    /**
     * 
     */
    titleProps: {
      type: Object,
      default: () => ({
        class: 'title',        
      })
    },
  },
}