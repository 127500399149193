import GListWebPageService from '@/services/api/General/GListWebPage'

export default {
  name: 'v-view-web-page',
  computed: {

    /**
     * 
     */
    permalink() {
      return this.permalinks[this.$route.params.permalink]
    }
  },
  data() {
    return {
      busy: {
        page: false,
      },
      dialogs:{
        page: false,
        webPage:{},
      },
      gWebPages: {
        data: [],
      },
      gWebPagesA: {
        data: [],
      },
      gWebPagesSeparates: [],

      src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
    }
  },
  filters: {

    /**
     * 
     */
    sheet: (gWebPages) => {
      return {
        backgroundImage: `url(${ gWebPages.fileUrl })`,
        backgroundPosition: `center`,
        backgroundSize: `${ gWebPages.size || 'cover' }`,
      }
    }
    ,sheetActives: (gWebPages) => {
      return {
        backgroundImage: `url(${ gWebPages.fileUrl })`,
        backgroundSize: `${ gWebPages.size || 'cover' }`,
        backgroundPosition: `center`,
        height: `295px`,
        
      }
    }
  },
  methods: {
    handleClickDialog(gWebPages){
      
      this.dialogs.webPage= gWebPages;
      this.dialogs.page = true;
    },
    handleOutDialog(){
      this.dialogs.webPage= {};
      this.dialogs.page = false;
    },
    /**
     
    async getWebPage() {
      this.busy.page = true
      //this.gWebPage = await GListWebPageService.getById(this.permalink)
      this.gWebPagesSeparates = await GListWebPageService.getAll()
      this.busy.page = false
      this.gWebPagesSeparates.data.forEach(contest => {
        if (contest.active==true) {
          this.gWebPagesActives.push(contest)
        }else{
          this.gWebPagesInactives.push(contest)
        }
      })
    },
      * 
     */
    /**
     * Método que trae todas los concursos inactivos en base al filter, para mostrar en el carrusel
     */
    getGWebPage() {
      
      GListWebPageService.query().paginate().then(res => {
        res.data = res.data.filter(gweb => gweb.active == false)
        this.gWebPages = res
        this.getGWebPage.data = res.data.map(item => {
          item.size = 'contain'
        })
      })
      
    },
    /**
     * Método que trae todas los concursos activos en base al filter, para mostrar en el carrusel
     */
    getGWebPageA() {
      GListWebPageService.query().paginate().then(res => {
        res.data = res.data.filter(gweb => gweb.active == true)
        this.gWebPagesA = res
        this.getGWebPageA.data = res.data.map(item => {
          item.size = 'contain'
        })
      })
    },
  },
  
  mounted() {
    //this.getWebPage()
    //se traen los concursos activos e inactivos cuando se monta el componente
    this.getGWebPage()
    this.getGWebPageA()
  },
  props: {
    /**
     * 
     */
    permalinks: {
      type: Object,
      default: () => ({
        concursos: 'contest',
      })
    }
  }
}