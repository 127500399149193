// modules
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import {
  VSnackbar,
  VTextField,
  VBtn,
  VIcon,
  VSwitch,
  VCarousel,
  VCarouselItem,
  VDivider,
  VSheet,
  VRow,
  VDialog,
  VCard,
  VCardTitle,
  VFileInput,
  VTooltip,
  VAutocomplete,
  VImg,
  VProgressCircular,
  VTimeline,
  VTimelineItem,
  VTextarea,
  VTabs,
  VTab,
  VTabsItems,
  VWindow,
  VWindowItem,
} from "vuetify/lib";
import { Scroll } from "vuetify/lib/directives";
import es from "vuetify/es5/locale/es";
import VuetifyToast from "vuetify-toast-snackbar";
import Embed from "v-video-embed";

// plugins
import App from "./app";
import Wrappers from "./wrappers";
import Toasts from "./toasts";

// install vuetify
Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VSwitch,
    VCarousel,
    VCarouselItem,
    VDivider,
    VSheet,
    VRow,
    VDialog,
    VCard,
    VCardTitle,
    VFileInput,
    VTooltip,
    VAutocomplete,
    VImg,
    VProgressCircular,
    VTextField,
    VTimeline,
    VTimelineItem,
    VTextarea,
    VTabs,
    VTab,
    VTabsItems,
    VWindow,
    VWindowItem,
  },
  directives: {
    Scroll,
  },
});

// aux vue instance
const vueObj = new Vuetify();

// install vuetify toast
Vue.use(VuetifyToast, {
  $vuetify: vueObj.framework,
  shorts: Toasts,
});

// install additional plugins
Vue.use(App);
Vue.use(Wrappers);
Vue.use(Embed);

// define
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0033a0",
        secondary: "#f08c04",
        accent: "#db9827",
        error: "#D32F2F",
        info: "#2196F3",
        success: "#388E3C",
        warning: "#FFA000",
        anchor: "#777af5",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
