import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { encryptString, decryptString } from "./plugins/crypto";
// import './scss/overrides.sass'

Vue.config.productionTip = false;
Vue.prototype.$encryptString = encryptString;
Vue.prototype.$decryptString = decryptString;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
