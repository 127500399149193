//

import axios from "axios";

//
export default {
  name: "v-view-home",
  data() {
    return {
      items: [
        { imagen: require("@/assets/images/teck_logo.png") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
        { imagen: require("@/assets/images/cmp_logo.jpg") },
      ],
      inscritos: [],
      event1: true,
      event2: true,
      event3: true,
      event4: true,
    };
  },
  computed: {},
  beforeMount() {
    this.handleVerify();
  },
  created() {},
  mounted() {},
  methods: {
    handleEventoClick(idEvento) {
      // if (this.isLogged) {
      // let idEncrypt = this.$encryptString(idEvento);
      // console.log("idEncrypt", idEncrypt);
      idEvento = this.$encryptString(idEvento.toString());
      this.$router.push({ name: "Inscripcion", params: { idEvento } });
      // } else {
      //   this.$router.push("/auth");
      // }
    },
    async handleVerify() {
      this.pProviderId = this.$store.state.ProviderModule.data.id;
      let verify = await axios.get(
        process.env.VUE_APP_API_ROUTE + "/inscriptions/" + this.pProviderId
      );
      this.inscritos = verify.data;
      let arrayEventosInscritos = [];
      this.inscritos.forEach((element) => {
        // if (element.g_event_id != 1) {
        //   this.event2 = true;
        //   this.event3 = true;
        //   this.event4 = true;
        // }
        // if (element.g_event_id != 2) {
        //   this.event2 = false;
        // }
        // if (element.g_event_id == 3) {
        //   this.event3 = false;
        // }
        // if (element.g_event_id == 4) {
        //   this.event4 = false;
        // }
        arrayEventosInscritos.push(element.g_event_id);
      });
      if (!arrayEventosInscritos.includes(1)) {
        this.event1 = true;
      }
      if (!arrayEventosInscritos.includes(2)) {
        this.event2 = true;
      }
      if (!arrayEventosInscritos.includes(3)) {
        this.event3 = true;
      }
      if (!arrayEventosInscritos.includes(4)) {
        this.event4 = true;
      }
    },
    disableEvent() {},
  },
};
