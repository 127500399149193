//
import AppInscripcionView from "@/views/App/inscripcion";

//
export default {
  path: "/inscripcion/:idEvento",
  name: "Inscripcion",
  component: AppInscripcionView,
  props: true,
};
