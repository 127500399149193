// required modules
import Service from "@/services/api/Service";
import GRegionModel from "@/models/General/GRegion";

/**
 * This class describes a GRegion service.
 *
 * @class GRegion (name)
 */
export default class GRegionService extends Service {
  /**
   * Define the GRegion model.
   *
   * @return {Function} GRegion resource.
   */
  static model() {
    return GRegionModel;
  }
  static getAll() {
    return this.query().limit(-1).get();
  }
  static getAllPaginate() {
    return this.query().limit(-1).paginate();
  }
  static getAllWithCommune() {
    return this.query().include("provinces.communes").limit(-1).get();
  }
}
