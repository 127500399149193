//
export default {
  error: {
    timeout: 300000,
    color: 'error darken-2',
    icon: 'mdi-alert-circle',
    multiLine: true,
    closeText: 'cerrar',
    showClose: true,  
    x: 'center',
  },
  info: {
    timeout: 15000,
    icon: 'mdi-information',
    color: 'info',
    closeText: 'cerrar',
    showClose: true,
    x: 'center',
  },
  wait: {
    icon: 'mdi-timelapse',
    color: 'grey darken-4',
    closeText: 'cerrar',
    showClose: true,
    x: 'center',
  },
  success: {
    icon: 'mdi-check',
    color: 'success darken-1',
    closeText: 'cerrar',
    showClose: true,
    x: 'center',
  },
}