// public pages
import AppHomeRoutes from "@/router/routes/App/AppHome";
import AppAboutRoutes from "@/router/routes/App/AppAbout";
import AppRedRoutes from "@/router/routes/App/AppRed";
import AppCumbreRoutes from "@/router/routes/App/AppCumbre";
import AppEventosRoutes from "@/router/routes/App/AppEventos";
import AppInscripcionRoutes from "@/router/routes/App/AppInscripcion";
import AppContactRoutes from "@/router/routes/App/AppContact";
import AppSearchRoutes from "@/router/routes/App/AppSearch";
import AppZoomRoutes from "@/router/routes/App/AppZoom";

// auth pages
import AuthRoutes from "@/router/routes/Auth";

// general pages
import GGoogleRoutes from "@/router/routes/General/GGoogle";
import GUserAccountRoutes from "@/router/routes/General/GUserAccount";
import GWebPageViewRoutes from "@/router/routes/General/GWebPage";
import GWebPageRedViewRoutes from "@/router/routes/General/GWebPageRed";

// mining company routes
import McLibraryRoutes from "@/router/routes/MiningCompany/McLibrary";

// provider pages
import PProviderRoutes from "@/router/routes/Provider/PProvider";

// views
export default [
  // app views
  AppHomeRoutes,
  AppAboutRoutes,
  AppRedRoutes,
  AppCumbreRoutes,
  AppEventosRoutes,
  AppInscripcionRoutes,
  AppContactRoutes,
  AppSearchRoutes,
  AppZoomRoutes,

  // auth views
  AuthRoutes,

  // general routes
  GGoogleRoutes,
  GUserAccountRoutes,
  GWebPageViewRoutes,
  GWebPageRedViewRoutes,

  // mining routes
  McLibraryRoutes,

  // provider routes
  PProviderRoutes,
];
