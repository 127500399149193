// required modules
import Service from "@/services/api/Service";
import GCommuneModel from "@/models/General/GCommune";

/**
 * This class describes a GCommune service.
 *
 * @class GCommune (name)
 */
export default class GCommuneService extends Service {
  /**
   * Define the GCommune model.
   *
   * @return {Function} GCommune resource.
   */
  static model() {
    return GCommuneModel;
  }

  static getAllPaginateByProvinceId(gProvinceId) {
    return this.query().where("provinceId", gProvinceId).limit(-1).paginate();
  }

  static getAllPaginateByRegionId(gRegionId) {
    return this.query().where("regionId", gRegionId).limit(-1).paginate();
  }

  static getAllPaginateByRegionIdAndByProvinceId(gRegionId, gProvinceId) {
    return this.query()
      .where("regionId", gRegionId)
      .where("provinceId", gProvinceId)
      .limit(-1)
      .paginate();
  }
  static getAllPaginateByCommuneId(gCommuneId) {
    return this.model().find(gCommuneId);
  }

  /**
   *
   */
  static getByProvinceId() {
    return Promise.resolve({
      data: [],
    });
  }
}
