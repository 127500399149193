// required modules
import Service from '@/services/api/Service'
import PCategoryModel from '@/models/Provider/PCategory'

/**
 * This class describes a PCategory service.
 *
 * @class PCategory (name)
 */
export default class PCategoryService extends Service {

  /**
   * 
   */
  static create(pCategory) {
    return this.shape(pCategory).save()
  }

  /**
   * Define the PCategory model.
   *
   * @return {Function} PCategory resource.
   */
  static model() {
    return PCategoryModel
  }

  /**
   * 
   */
  static getAll() {
    return this
      .query()
      .limit(-1)
      .get()
  }

  /**
   * 
   */
  static getBy(opts = {}) {
    return this
      .query()
      .page(opts.page)
      .limit(opts.limit)
      .paginate()
  }

  /**
   * 
   */
  static getAllPaginate() {
    return this
      .query()
      .limit(-1)
      .paginate()
  }

  /**
   * 
   * @param {*} pCategory 
   */
  static toCreate() {
    return this.shape({
      name: '',
    })
  }

  /**
   * 
   */
  static update(pCategory) {
    return this.shape(pCategory).save()
  }
}