// required modules
import Model from '@/models/Model'

/**
 * This class describes a GWebPage model.
 *
 * @class GWebPageRed
 */
export default class GListWebPageRedModel extends Model {
  
  get fileUrl() {
    return `${ this.$baseUrl +'/'+ this.$resource }/content?filePath=${ this.filePath }`
  }
  /**
   * Define the GWebPage resource.
   *
   * @return {String} GWebPage endpoint name.
   */
  static resource() {
    return 'web_page_red'
  }

}