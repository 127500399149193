// required modules
import Model from '@/models/Model'

/**
 * This class describes a GUserAccount model.
 *
 * @class GUserAccount
 */
export default class GUserAccountModel extends Model {

  /**
   * Define the GUserAccount resource.
   *
   * @return {String} GUserAccount endpoint name.
   */
  static resource() {
    return 'user_account'
  }

  /**
   * 
   */
  requestResetPassword() {
    return this.many('request_reset_password')
  }

  /**
   * 
   */
  toRequestResetPassword() {
    return this.reduce([
      'rut'
    ])
  }
}