// required modules
import Model from '@/models/Model'

/**
 * This class describes a GZoom model.
 *
 * @class GZoom
 */
export default class GZoomModel extends Model {

  /**
   * Define the GZoom resource.
   *
   * @return {String} GZoom endpoint name.
   */
  static resource() {
    return 'zoom'
  }
}