<template lang='pug'>
  v-app#app
    v-layout-header(v-if='$vuetify.breakpoint.smAndUp' @click-menu='drawers.menu = !drawers.menu' )
    v-layout-nav( @click-menu='drawers.menu = !drawers.menu' )
    v-layout-drawer( v-model='drawers.menu' )
    router-view
    v-layout-footer
</template>

<script>
  export default {
    name: 'app',
    components: {
      VLayoutDrawer: () => import(/* webpackChunkName: "footer" *//* webpackPrefetch: true */ './layouts/drawer'),
      VLayoutFooter: () => import(/* webpackChunkName: "footer" *//* webpackPrefetch: true */ './layouts/footer'),
      VLayoutHeader: () => import(/* webpackChunkName: "header" *//* webpackPrefetch: true */ './layouts/header'),
      VLayoutNav: () => import(/* webpackChunkName: "header" *//* webpackPrefetch: true */ './layouts/nav'),
    },
    data() {
      return {
        drawers: {
          menu: false,
        }
      }
    },
    beforeMount() {
      this.$store.dispatch('AuthModule/refresh')
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  // @import '~vuetify/src/components/VStepper/_variables.scss';
  @font-face {
    font-family: "CidereBold";
    src: local("CidereBold"),
    url(./fonts/Oswald-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "CidereLight";
    src: local("CidereLight"),
    url(./fonts/Oswald-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "CidereRegular";
    src: local("CidereRegular"),
    url(./fonts/Oswald-Regular.ttf) format("truetype");
  }
  $body-font-family: 'CidereBold','IBM Plex Sans', serif;
  @import '~vuetify/src/styles/styles.sass';
  // @import '~vuetify/src/components/VStepper/_variables.scss';
  .cidere-light-title{
  font-family: "CidereLight", Helvetica, Arial !important;
  font-size: 42px;
}

.cidere-bold-title{
  font-family: "CidereBold", Helvetica, Arial !important;
  font-size: 24px !important;
}
.cidere-regular-title{
  font-family: "CidereRegular", Helvetica, Arial !important;
  font-size: 14px !important;
}
</style>


