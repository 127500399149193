// required modules
import Model from '@/models/Model'

/**
 * This class describes a PProvider model.
 *
 * @class PProvider
 */
export default class PProviderModel extends Model {

  /**
   * 
   */
  constructor(data) {
    super(data)
    // set only if has nested data
    //console.log('the data',data)
    this.categoryId = this.prop('category.id', null)
    this.communeId  = this.prop('commune.id', null)
    this.provinceId = this.prop('commune.province.id', null)
    this.regionId   = this.prop('commune.province.region.id', null)
    this.sizeId     = this.prop('size.id', null)
    this.longitude  = this.prop('address.longitude', 0)
    this.latitude   = this.prop('address.latitude', 0)
    this.address    = this.prop('address.address', 0)
  }

  /**
   * 
   */
  get nameShort() {
    return this
      .prop('name', '')
      .split(' ')
      .map(e => e.charAt(0))
      .join('')
      .substr(0, 2)
  }

  /**
   * 
   */
  get smallLogoInBase64() {
    return `data:image/jpeg;base64,${ this.smallLogo || 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkMF5QDwACYgFULehhgAAAAABJRU5ErkJggg==' }` 
  }

  /**
   * 
   */
  get mediumLogoInBase64() {
    return `data:image/jpeg;base64,${ this.mediumLogo || 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkMF5QDwACYgFULehhgAAAAABJRU5ErkJggg==' }` 
  }

  /**
   * 
   */
  get largeLogoInBase64() {
    return `data:image/jpeg;base64,${ this.largeLogo || 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkMF5QDwACYgFULehhgAAAAABJRU5ErkJggg==' }` 
  }

  /**
   * 
   */
  logo() {
    return this.many('logo')
  }

  /**
   * 
   */
  get permanent_identity() {
    return `PROVIDER-${ this.$identity }`
  }

  /**
   * 
   */
  get encrypt_identity() {
    return btoa(this.permanent_identity)
  }

  /**
   * 
   */
  get permalink() {
    return `/proveedor/${ this.encrypt_identity }`
  }

  /**
   * Retorna el porcentaje completado de las actividades económicas en el perfil 
   * del proveedor.
   * @type {number}
   */
  get profileEconomicActivitiesPercent() {
    var percent = 100
    if (!this.economicActivities.length) percent-= 50
    if (!this.services.length) percent-= 50
    return percent
  }

  /**
   * Retorna el porcentaje completado de la información general en el perfil 
   * del proveedor.
   * @type {number}
   */
  get profileGeneralPercent() {
    var percent = 100
    if (!this.address) percent-= 25
    if (!this.smallLogo) percent-= 25
    if (!this.description) percent-= 25
    if (!this.webUrl) percent-= 25
    return percent
  }

  /**
   * Retorna el porcentaje completado del representante legal en el perfil 
   * del proveedor.
   * @type {number}
   */
  get profileLegalPercent() {
    var percent = 100
    if (!this.legalRepresentative.name) percent-= 40
    if (!this.legalRepresentative.phone) percent-= 30
    if (!this.legalRepresentative.rut) percent-= 30
    return percent
  }

  /**
   * Retorna el porcentaje completado del perfil del proveedor.
   * @type {number}
   */
  get profilePercentComplete() {
    
    // required
    var percent = 100

    // decrement if not has address
    if (this.address == "") {
      percent-= 10
    }

    // decrement if not has address
    if (this.description == "") {
      percent-= 10
    }

    // decrement if not has economic activities
    if (!this.economicActivities.length) {
      percent-= 10
    }

    // decrement if not has legal representative data
    if (this.legalRepresentative.name == "" || this.legalRepresentative.phone == "" || this.legalRepresentative.rut == "") {
      percent-= 10
    }

    // decrement if not has small logo
    if (this.smallLogo == "") {
      percent-= 10
    }

    // decrement if not has services
    if (!this.services.length) {
      percent-= 10
    }

    // decrement if not has survey answered
    if (!this.surveys[0].answered) {
      percent-= 10
    }

    // decrement if not has web url
    if (this.webUrl == "") {
      percent-= 10
    }

    // result
    return percent
  }

  /**
   * Define the PProvider resource.
   *
   * @return {String} PProvider endpoint name.
   */
  static resource() {
    return 'provider'
  }

  /**
   * 
   */
  get scoreColor() {
    return ['grey lighten-2', 'grey', 'success', 'black'][this.scoreId]
  }

  /**
   * 
   */
  get scoreId() {
    return this.scoreIdValue
  }

  /**
   * 
   */
  get scoreName() {
    return this.scoreNameValue
  }

  /**
   * 
   */
  get scorePercentage() {
    return this.scoreValue
  }

  /**
   * 
   */
  get scoreValue() {
    return this.prop('scores.0.score', 0)
  }

  /**
   * 
   */
  get scoreNameValue() {
    return this.prop('scores.0.name', 'Sin Cinturón')
  }

  /**
   * 
   */
  get scoreIdValue() {
    return this.prop('scores.0.id', 0)
  }

/**
   * 
   */
get scoreColorFaena() {
  return ['grey lighten-2', 'grey', 'success', 'black'][this.scoreIdFaena]
}

/**
 * 
 */
get scoreIdFaena() {
  return this.scoreIdValueFaena
}

/**
 * 
 */
get scoreNameFaena() {
  return this.scoreNameValueFaena
}

/**
 * 
 */
get scorePercentageFaena() {
  return this.scoreValueFaena
}

/**
 * 
 */
get scorePercentageSello() {
  return this.scoreValueSello
}

/**
 * 
 */
get scoreValueFaena() {
  return this.prop('scores.1.score', 0)
}

/**
 * 
 */
get scoreValueSello() {
  return this.prop('scores.2.score', 0)
}

/**
 * 
 */
get scoreNameValueFaena() {
  return this.prop('scores.1.name', 'Sin Cinturón')
}

/**
 * 
 */
get scoreNameValueSello() {
  return this.prop('scores.2.name', 'Sin Sello')
}


/**
 * 
 */
get scoreIdValueSello() {
  return this.prop('scores.2.id', 0)
}

/**
 * 
 */
get scoreIdValueFaena() {
  return this.prop('scores.1.id', 0)
}
  /**
   * 
   */
  get surveyAnswered() {
    if(this.surveys[0].faena){
      return this.surveys[1].answered
    }else{
      return this.surveys[0].answered
    }
  }
    
    
    
    get surveyAnsweredFaena(){
    if(this.surveys[0].faena){
      return this.surveys[0].answered
    }else{
      return this.surveys[1].answered
    }
    }
    
    
    
    get surveyBlocked() {
      if(this.surveys[0].faena){
        return this.surveys[1].blocked
      }else{
        return this.surveys[0].blocked
      }
    }
    
    
    
    get surveyBlockedFaena(){
      if(this.surveys[0].faena){
        return this.surveys[0].blocked
      }else{
        return this.surveys[1].blocked
      }
    }
  /**
   * 
   */
  toCertifications() {
    return {
      certifications: this.prop('certifications', []).map(e => e.id)
    }
  }

  /**
   * 
   */
  toDocument() {
    return this.form([
      'name',
      'file',
      'g_provider_document_type_id',
    ])
  }

  /**
   * 
   */
  toLogo() {
    return this.form([
      'image',
    ])
  }

  /**
   * PProvider update model structure
   * 
   * @returns {PProviderModel} PProvider model
   */
  toUpdate() {
    return this.reduce([
      'address',
      'businessName',
      'categoryId',
      'communeId',
      'description',
      'email',
      'id',
      'latitude',
      'longitude',
      'name',
      'sizeId',
      'webUrl',
    ])
  }
}