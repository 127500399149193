//
import GImageGalleryService from '@/services/api/General/GImageGallery'
//
export default {
  name: 'v-view-home',
  data: () => ({
    model: 0,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    gImageGallery: {
      data: [],
    },
    banner: {
      data: [],
    },
  }),
  filters: {

    /**
     * 
     */
    sheet: (gImageGallery) => {
      console.log("gImageGallery",gImageGallery)
      console.log("backgroundImage",gImageGallery.size)
      return {
        backgroundImage: `url(${ gImageGallery.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize: 'contain',
      }
    },
    sheetC: (gImageGallery) => {
      return {
        marginTop: '0px',
        backgroundImage: `url(${ gImageGallery.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize: `${ gImageGallery.size || 'contain' }`,
      }
    },
    sheet2: (banner) => {
      banner.fileUrl = process.env.VUE_APP_API_ROUTE +"/image_gallery/content?filePath=banners/banner-nosotros.jpg"
      console.log("backgroundImage2",banner.fileUrl)
      return {
        backgroundImage: `url(${ banner.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize: 'contain',
      }
    },
    sheet2C: (banner) => {
      banner.fileUrl = process.env.VUE_APP_API_ROUTE +"/image_gallery/content?filePath=banners/banner-nosotros.jpg"
      console.log("backgroundImage2",banner.fileUrl)
      return {
        marginTop: '0px',
        backgroundImage: `url(${ banner.fileUrl })`,
        backgroundPosition: `center center`,
        backgroundSize:  'contain',
      }
    },
  },
  methods: {

    /**
     * 
     */
    getImageGallery() {
      GImageGalleryService.query().paginate().then(res => {
        this.gImageGallery = res
      })
    },
  },
  mounted() {
    this.getImageGallery()
  }
}