// required modules
import Model from '@/models/Model'

/**
 * This class describes a PSubcategory model.
 * @class PSubcategory
 */
export default class PSubcategoryModel extends Model {

  /**
   * 
   */
  constructor(payload) {
    super(payload)
    this.ids = [this.id]
  }

  /**
   * Get the subcategory name with the first letter in upper case transform.
   * @type {String}
   */
  get nameWithFirstLetterInUpperCase() {
    var nameStr     = String(this.name)
    var letterStr   = nameStr.charAt(0).toUpperCase()
    var bodyStr     = nameStr.slice(1).toLocaleLowerCase()
    var newNameStr  = letterStr + bodyStr
    return newNameStr
  }

  /**
   * Define the PSubcategory resource.
   * @return {String} PSubcategory endpoint name
   */
  static resource() {
    return 'subcategory'
  }
}