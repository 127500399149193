// wrappers layouts
import AppLayoutAlert from '@/layouts/alert'
import AppLayoutForm from '@/layouts/form'
import AppLayoutFrame from '@/layouts/frame'

// wrappers components
import { VwValidation, VwDateTimeField, VwDateField, VwTimeField } from 'vuetify-wrappers/v2'
import { VwRutField, VwSelect } from 'vuetify-wrappers/v2'

// wrappers plugin
export default {
  install: function (Vue) {
    Vue.mixin({
      components: {
        AppLayoutAlert,
        AppLayoutForm,
        AppLayoutFrame,
        VwRutField,
        VwSelect,
        VwValidation,
        VwDateField,
        VwDateTimeField,
        VwTimeField,
      },
    })
  }
}
