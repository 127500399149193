//
import PProviderSearchView from '@/views/Provider/PProvider/search'

//
export default {
  path: '/buscar',
  name: 'search',
  component: PProviderSearchView,
  children:[
    {
      path: ':id',
      name: 'search_id',
      component: PProviderSearchView,
      props: true,
    },
  ],
}