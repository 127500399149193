// required modules
import axios from 'axios'
import Service from '@/services/api/Service'
import GZoomModel from '@/models/General/GZoom'

/**
 * This class describes a GZoom service.
 *
 * @class GZoom (name)
 */
export default class GZoomService extends Service {

  /**
   * 
   */
  static access(gZoom) {
    return axios.request({
      method: 'post',
      url: gZoom.many('access').$uri,
      headers: {
        authorization: `Bearer ${ gZoom.authToken }`
      },
      data: {
        code: gZoom.code,
        redirectUri: gZoom.redirectUri,
      },
    })
  }

  /**
   * 
   */
  static getBaseLink() {
    return `https://zoom.us/oauth/authorize`
  }

  /**
   * 
   */
  static getClientId() {
    return process.env.VUE_APP_ZOOM_CLIENT_ID
  }

  /**
   * 
   */
  static getLink() {
    return `${ this.getBaseLink() }?response_type=code&client_id=${ this.getClientId() }&redirect_uri=${ this.getRedirectUri() }`
  }

  /**
   * 
   */
  static getRedirectUri() {
    return `https://eindustrial.api.sys.dev.innlab.cl/api/zoom-access`
  }

  /**
   * Define the GZoom model.
   *
   * @return {Function} GZoom resource.
   */
  static model() {
    return GZoomModel
  }
}