// auth service
import axios from 'axios'
// import AthLoginService from '@/services/api/Auth/AthLogin'

// auth store module
export default {
  namespaced: true,
  actions: {

    /**
     * Commit the set auth service data
     * @param {*} context 
     * @param {*} payload 
     */
    setData: (context, payload) => {
      context.commit('setData', payload.data)
    },

    /**
     * 
     */
    storeData: (context) => {
      localStorage.setItem('eim-auth', JSON.stringify(context.state.data))
    },

    /**
     * 
     */
    refresh: (context) => {
      context.commit('setData', context.state.data)
    },

    /**
     * Commit the reset auth module
     * @param {*} context 
     * @param {*} payload 
     */
    reset: (context) => {
      context.commit('setData', {})
      localStorage.setItem("filters", '{}');
      context.dispatch('storeData')
    },
  },
  getters: {

    /**
     * 
     */
    hasToken(state) {
      return !! state.data.accessToken
    },

    /**
     * 
     */
    token(state) {
      return `${ state.data.tokenType } ${ state.data.accessToken }`
    },
  },
  mutations: {

    /**
     * Set the auth service data
     * 
     * @param {*} state 
     * @param {*} data 
     */
    setData: (state, data) => {

      // intercept axios
      if (data.accessToken) {
        axios.interceptors.request.eject(state.foo)

        let foo = axios.interceptors.request.use(config => {
          config.headers.Authorization = `${ data.tokenType } ${ data.accessToken }`
          return config
        })

        //
        state.foo   = foo
      }

      //
      state.data  = data

      if(localStorage.getItem('eim-auth') == '{}'){
        localStorage.setItem("filters", '{}');
      }
    },
  },
  state: {
    data: JSON.parse(localStorage.getItem('eim-auth') || '{}'),
    key: '',
    url: process.env.VUE_APP_API_AUTH_ROUTE,
    foo: () => {},
  },
}