import CryptoJS from "crypto-js";

const secretKey = "industrial"; // La clave secreta para el cifrado

export function encryptString(plainText) {
  const ciphertext = CryptoJS.AES.encrypt(plainText, secretKey).toString();
  return ciphertext;
}

export function decryptString(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);
  return plainText;
}
