import GListWebPageRedService from '@/services/api/General/GListWebPageRed'

export default {
  name: 'v-view-web-page-red',
  computed: {

    /**
     * 
     */
    permalink() {
      return this.permalinks[this.$route.params.permalink]
    }
  },
  data() {
    return {
      busy: {
        page: false,
      },
      dialogs:{
        page: false,
        webPageRed:{},
      },
      gWebPageReds: {
        data: [],
      },
      gWebPageRedsA: {
        data: [],
      },
      gWebPageRedsSeparates: [],

      src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
    }
  },
  filters: {

    /**
     * 
     */
    sheet: (gWebPageReds) => {
      return {
        backgroundImage: `url(${ gWebPageReds.fileUrl })`,
        backgroundPosition: `center`,
        backgroundSize: `${ gWebPageReds.size || 'cover' }`,
      }
    }
    ,sheetActives: (gWebPageReds) => {
      return {
        backgroundImage: `url(${ gWebPageReds.fileUrl })`,
        backgroundSize: `${ gWebPageReds.size || 'cover' }`,
        backgroundPosition: `center`,
        height: `295px`,
        
      }
    }
  },
  methods: {
    handleClickDialog(gWebPageReds){
      
      this.dialogs.webPageRed= gWebPageReds;
      this.dialogs.page = true;
    },
    handleOutDialog(){
      this.dialogs.webPageRed= {};
      this.dialogs.page = false;
    },
    /**
     
    async getWebPage() {
      this.busy.page = true
      //this.gWebPage = await GListWebPageService.getById(this.permalink)
      this.gWebPagesSeparates = await GListWebPageService.getAll()
      this.busy.page = false
      this.gWebPagesSeparates.data.forEach(contest => {
        if (contest.active==true) {
          this.gWebPagesActives.push(contest)
        }else{
          this.gWebPagesInactives.push(contest)
        }
      })
    },
      * 
     */
    /**
     * Método que trae todas los concursos inactivos en base al filter, para mostrar en el carrusel
     */
    getGWebPageRed() {
      
      GListWebPageRedService.query().paginate().then(res => {
        res.data = res.data.filter(gwebr => gwebr.active == false)
        this.gWebPageReds = res
        this.getGWebPageRed.data = res.data.map(item => {
          item.size = 'contain'
        })
      })
      
    },
    /**
     * Método que trae todas los concursos activos en base al filter, para mostrar en el carrusel
     */
    getGWebPageRedA() {
      GListWebPageRedService.query().paginate().then(res => {
        res.data = res.data.filter(gwebr => gwebr.active == true)
        this.gWebPageRedsA = res
        this.getGWebPageRedA.data = res.data.map(item => {
          item.size = 'contain'
        })
      })
    },
  },
  
  mounted() {
    //this.getWebPage()
    //se traen los concursos activos e inactivos cuando se monta el componente
    this.getGWebPageRed()
    this.getGWebPageRedA()
  },
  props: {
    /**
     * 
     */
    permalinks: {
      type: Object,
      default: () => ({
        noticias: 'red',
      })
    }
  }
}