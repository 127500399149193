// required modules
import Service from "@/services/api/Service";
import GEventDetailProviderModel from "@/models/General/GEventDetailProvider";

/**
 * This class describes a GEventDetailProvider service.
 *
 * @class GEventDetailProvider (name)
 */
export default class GEventDetailProviderService extends Service {
  /**
   * Define the GEventDetailProvider model.
   *
   * @return {Function} GEventDetailProvider resource.
   */
  static model() {
    return GEventDetailProviderModel;
  }

  /**
   *
   */
  static getById(code = "") {
    return this.query().find(code);
  }

  /**
   *
   */
  static update(gWebPage) {
    return gWebPage.sync(gWebPage);
  }

  static register(form) {
    return this.shape(form).save();
  }
}
