// user store module
export default {
  namespaced: true,
  actions: {

    /**
     * Commit the set user service data
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    setData(context, payload) {
      context.commit('setData', payload.data)
    },

    /**
     * 
     */
    storeData: (context) => {
      localStorage.setItem('eim-user', JSON.stringify(context.state.data))
    },

    /**
     * Commit the reset user module
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    reset(context) {
      context.commit('setData', {})
      context.dispatch('storeData')
    },
  },
  getters: {

    /**
     * 
     */
    hasData(state) {
      for(var prop in state.data) {
        return true
      }
      return false
    },

    /**
     * 
     */
    notData(state) {
      for(var prop in state.data) {
        return false
      }
      return true
    },
  },
  mutations: {

    /**
     * Set the user service data
     * 
     * @param {*} state 
     * @param {*} data 
     */
    setData(state, data) {
      state.data = data
    },
  },
  state: {
    data: JSON.parse(localStorage.getItem('eim-user') || '{}'),
  },
}