// required modules
import Model from '@/models/Model'
import GProvince from '@/models/General/GProvince'

/**
 * This class describes a GRegion model.
 *
 * @class GRegion
 */
export default class GRegionModel extends Model {

  /**
   * 
   */
  getProvinces() {
    return this.map('provinces', GProvince)
  }

  /**
   * Define the GRegion resource.
   *
   * @return {String} GRegion endpoint name.
   */
  static resource() {
    return 'region'
  }
}