//
//
// import AthLoginService from "@/services/api/Auth/AthLogin";
import AthRegisterService from "@/services/api/Auth/AthRegister";
import GEventService from "@/services/api/General/GEvent";
import GRegionService from "@/services/api/General/GRegion";
import GProvinceService from "@/services/api/General/GProvince";
import GCommuneService from "@/services/api/General/GCommune";
import PCategoryService from "@/services/api/Provider/PCategory";
import PProviderService from "@/services/api/Provider/PProvider";
import GUserAccountService from "@/services/api/General/GUserAccount";
import PSizeService from "@/services/api/Provider/PSize";
import PSubcategoryService from "@/services/api/Provider/PSubcategory";
import RulesService from "@/services/rules";
import GEventDetailProviderService from "@/services/api/General/GEventDetailProvider";
import axios from "axios";

export default {
  name: "v-view-home",
  data() {
    return {
      tab: null,
      pTiposA: [
        { id: 1, name: "Bombas de bajo caudal " },
        {
          id: 2,
          name: "Elementos de computación (proveedor que venda computadores, accesorios de computación, disco duro, mouse y todo lo relacionado)",
        },
        { id: 3, name: "Ferretería industrial (Materiales de ferretería)" },
        { id: 4, name: "Gases Industriales" },
        { id: 5, name: "Insumos eléctricos / Herramientas" },
        { id: 6, name: "Librería " },
        { id: 7, name: "Materiales de fundición o piezas fundidas" },
        { id: 8, name: "Productos de sellado de fluidos (cintas reparadoras)" },
        {
          id: 9,
          name: "Químicos y reactivos (urea, bichofita, ácido nítrico, entre otros)",
        },
        { id: 10, name: "Regalos corporativos" },
        { id: 11, name: "Ropa deportiva" },
        { id: 12, name: "Ropa dieléctrica / Ignifuga" },
        { id: 13, name: "Seguridad industrial (Ropa y EPP)" },
        {
          id: 14,
          name: "Suministro Accesorio de Cintas Transportadoras (Gualderas, raspadores, bastidores, telescópicos, entre otros)",
        },
        { id: 15, name: "Suministro de agua embotellada" },
        {
          id: 16,
          name: "Suministro de kit de empalmes y parches correas transportadoras",
        },
        { id: 17, name: "Suministro de planchas, perfiles y barras de acero" },
        { id: 18, name: "Suministro de polines correas transportadoras" },
        {
          id: 19,
          name: "Suministro Luminarias Fijas y Móviles (p.ej: Tubos fluorescentes, luminaria industrial, linternas o luminarias led, entre otros)",
        },
        {
          id: 20,
          name: "Suministro Repuestos Generales chancadores (Anillos de Sacrificio, Bujes, pernos, flexibles, golillas, entre otros)",
        },
        { id: 21, name: "Válvulas y fitting de HDPE" },
        { id: 22, name: "Ninguno" },
      ],
      pTiposB: [
        { id: 1, name: "Análisis calidad de agua (laboratorio)" },
        { id: 2, name: "Arriendo de contenedores (Remodelados) " },
        {
          id: 3,
          name: "Arriendo equipos para: limpieza industrial (supersucker), grúas, camas baja horquillas",
        },
        { id: 4, name: "Banquetería " },
        { id: 5, name: "Brigada de seguridad (pej.red de incendio) " },
        { id: 6, name: "Limpieza con camión de alta presión" },
        {
          id: 7,
          name: "Manejo de animales silvestres. Implementar actividades de mejoramiento del hábitat que permita un incremento de la biodiversidad",
        },
        { id: 8, name: "Mantención de áreas verdes" },
        { id: 9, name: "Mantención de caminos" },
        { id: 10, name: "Mantención de compresores" },
        { id: 11, name: "Mantención de estructuras " },
        {
          id: 12,
          name: "Medidas de Hidrosiembra. Revegetar con las especies estructurantes",
        },
        { id: 13, name: "Servicio de Análisis de Aguas y Alimentación " },
        { id: 14, name: "Servicio de Escoltas" },
        { id: 15, name: "Servicio de Mantención Forestal y Riego " },
        {
          id: 16,
          name: "Servicio de Mantenimiento de Equipos de Refrigeración, Climatización y Presurizadores de Aire",
        },
        { id: 17, name: "Servicio de movimiento de tierra" },
        {
          id: 18,
          name: "Servicio de Reparación y Mantenimiento general de Piscinas ML",
        },
        {
          id: 19,
          name: "Servicio de seguimiento y monitoreo de aves a lo largo del trazado de construcción de la Línea de Transmisión Eléctrica (LTE)",
        },
        { id: 20, name: "Servicio Laboratorio de Ensayos a la Construcción" },
        {
          id: 21,
          name: "Sistemas de seguridad CCTV, Radiocuminicaciones y soporte",
        },
        { id: 22, name: "Sistemas supresores de incendio en equipos mina" },
        { id: 23, name: "Soldadura HDPE" },
        {
          id: 24,
          name: "Soluciones tecnológicas en cualquier materia que signifique automatización o mejoras de procesos ",
        },
        { id: 25, name: "Sondajes" },
        { id: 26, name: "Suministro Aceros de Perforación" },
        { id: 27, name: "Ninguno " },
      ],
      pCategorias: [
        {
          id: 1,
          name: "Equipos y Maquinaria (equipos de perforación, excavación, maquinaria de carga y transporte, trituración, molienda, sistemas de ventilación y extracción, otros similares).",
        },
        {
          id: 2,
          name: "Tecnología y Software (sistemas de automatización, software de gestión minera, sistemas de monitoreo y control, tecnología de sensores y seguridad, otros similares)",
        },
        {
          id: 3,
          name: "Servicios de Ingeniería y Consultoría (consultoría en exploración y evaluación de yacimientos, servicios de diseño, habilitación y/o construcción de infraestructura, consultoría en sostenibilidad y medio ambiente, otros similares)",
        },
        {
          id: 4,
          name: "Suministros y Materiales (suministros eléctricos y mecánicos, productos químicos y reactivos, materiales de construcción, otros similares)",
        },
        {
          id: 5,
          name: "Servicios de mantenimiento y Reparación (mantenimiento de maquinaria pesada, reparación de equipos eléctricos y electrónicos, servicios de calibración y pruebas, otros similares)",
        },
        {
          id: 6,
          name: "Seguridad y Salud Ocupacional (equipos de protección personal (EPP), sistemas de seguridad industrial, servicios de capacitación en seguridad, otros similares)",
        },
        {
          id: 7,
          name: "Transporte y Logística (Servicios de transporte de materiales, transporte de personal, gestión de la cadena de suministro, almacenamiento y distribución)",
        },
        {
          id: 8,
          name: "Financiamiento y seguros",
        },
        {
          id: 9,
          name: "Capacitación y desarrollo profesional ",
        },
        {
          id: 10,
          name: "Servicios legales y de cumplimiento",
        },
        {
          id: 11,
          name: "Otro (Especificar)",
        },
      ],
      activeSlide: 0,
      autoplayInterval: null,
      transitioning: false,
      rules: {
        email: true,
        rut: true,
      },
      gRegions: [],
      gCommunes: [],
      gProvinces: [],
      pProvider: null,
      pProviderId: null,
      pSubcategories: [],
      acceding: false,
      busy: {
        email: false,
        registry: false,
        rut: false,
        searching: false,
        subcategory: false,
        registryA: false,
        registryB: false,
        registryC: false,
        pType: false,
      },
      athRegisterA: {
        is_provider: true,
        pTypeId: "",
      },
      athRegisterB: [],
      athRegisterC: {
        pTiposASelected: [],
      },

      athRegisterD: {},
      athRegisterE: {
        20: [],
        21: [],
      },
      athRegister: {},
      subcategories: [],
      siiProvider: {
        status: 500,
      },
      dialogs: {
        success: false,
      },
      pTypes: [],
      evento: {},
      isChecked1: false,
      isChecked2: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async handleProviderFetch() {
      this.pProviderId = this.$store.state.ProviderModule.data.id;
      this.pProvider = await PProviderService.getById(this.pProviderId);
      this.athRegisterA = { ...this.pProvider };
      // this.athRegisterA.push({ communeId: this.pProvider.commune.id });
      let comunas = await GCommuneService.getAllPaginateByCommuneId(
        this.athRegisterA.communeId
      );
      this.athRegisterA.communeId = this.pProvider.communeId;
      this.athRegisterA.provinceId = comunas.g_province_id;
      this.athRegisterA.regionId = comunas.province.g_region_id;
      this.athRegisterA.pTypeId = this.pProvider.type.id;
    },
    async handleFormSubmitA() {
      this.handleFormVerifiedA();
    },
    async handleFormVerifiedA() {
      this.busy.registryA = true;
      await this.$store.dispatch("RegisterModule/setData", {
        data: this.athRegisterA,
      });
      await axios
        .post(
          process.env.VUE_APP_API_ROUTE + "/provider/form1/" + this.pProviderId,
          this.athRegisterA
        )
        .then(this.moveTab)
        .catch(this.handleRegisterError);
      window.lintrk("track", { conversion_id: 15269434 });
      this.busy.registryA = false;
    },
    async handleFormSubmitB() {
      this.handleFormVerifiedB();
    },
    async handleFormVerifiedB() {
      this.busy.registryB = true;
      let merged = { ...this.athRegisterB };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      let formulario = this.conversionForm(merged);
      await GEventDetailProviderService.register(formulario)
        .then(this.handleRegisterSuccess)
        .catch(this.handleRegisterError);
      window.lintrk("track", { conversion_id: 15269434 });
      this.busy.registryB = false;
    },
    conversionForm(data) {
      let form = [];
      let ed = this.evento.event_details;
      let tam = ed.length - 1;
      for (let i = 0; i < tam; i++) {
        if (i == 0) {
          form.push({
            p_provider_id: this.pProviderId,
            g_event_detail_id: ed[i].id,
            input_value: {
              value: "true",
            },
          });
        }
        form.push({
          p_provider_id: this.pProviderId,
          g_event_detail_id: ed[i + 1].id,
          input_value: {
            value: data[ed[i + 1].id],
          },
        });
      }
      form = { form: form };
      return form;
    },
    async handleFormSubmitC() {
      this.handleFormVerifiedC();
    },
    async handleFormVerifiedC() {
      this.busy.registryC = true;
      let merged = { ...this.athRegisterC };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      let formulario = this.conversionForm(merged);
      if (
        (this.athRegisterA.pTypeId == 1 &&
          this.athRegisterC[11] == undefined) ||
        (this.athRegisterA.pTypeId == 2 && this.athRegisterC[12] == undefined)
      ) {
        this.handleFormError();
        this.busy.registryC = false;
      } else {
        await GEventDetailProviderService.register(formulario)
          .then(this.handleRegisterSuccess)
          .catch(this.handleFormError);
        window.lintrk("track", { conversion_id: 15269434 });
        this.busy.registryC = false;
      }
    },
    async handleFormSubmitD() {
      this.handleFormVerifiedD();
    },
    async handleFormVerifiedD() {
      this.busy.registryD = true;
      let merged = { ...this.athRegisterD };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      let formulario = this.conversionForm(merged);
      await GEventDetailProviderService.register(formulario)
        .then(this.handleRegisterSuccess)
        .catch(this.handleRegisterError);
      window.lintrk("track", { conversion_id: 15269434 });
      this.busy.registryD = false;
    },
    async handleFormSubmitE() {
      this.handleFormVerifiedE();
    },
    async handleFormVerifiedE() {
      this.busy.registryE = true;
      let merged = { ...this.athRegisterE };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      let formulario = this.conversionForm(merged);
      if (
        this.athRegisterE[20] == undefined ||
        this.athRegisterE[21] == undefined
      ) {
        this.handleFormError();
        this.busy.registryE = false;
      } else {
        await GEventDetailProviderService.register(formulario)
          .then(this.handleRegisterSuccess)
          .catch(this.handleRegisterError);
        window.lintrk("track", { conversion_id: 15269434 });
        this.busy.registryE = false;
      }
    },
    moveTab() {
      // console.log(parseInt(this.idEvento));
      // this.tab = parseInt(this.idEvento);
      this.tab = 1;
    },
    async handleTypeFetch() {
      let tipos = await axios.get(
        process.env.VUE_APP_API_ROUTE + "/provider/type"
      );
      this.pTypes = tipos.data;
    },
    async handleEventFetch() {
      this.evento = await GEventService.getAllWithDetails(this.idEvento);
    },
    async handleRegionFetch() {
      this.gRegions = await GRegionService.getAllWithCommune();
    },
    async handleProvinceFetch(gRegionId) {
      this.gProvinces = await GProvinceService.getAllPaginateByRegionId(
        gRegionId
      );
    },
    handleDialogClick() {
      this.acceding = true;
      this.acceding = false;
      this.dialogs.success = false;
      this.$router.push("/eventos");
    },
    handleDialogClickSalir() {
      this.acceding = true;
      this.acceding = false;
      this.dialogs.success = false;
      this.$router.push("/");
    },
    handleRegisterError(payload) {
      AthRegisterService.translate(payload).then(this.$toast.error);
      this.tab = 0;
    },
    handleRegisterSuccess(res) {
      this.pProvider = PProviderService.shape({
        id: res.data.providerId,
      });
      this.pProvider.subcategories = this.subcategories.map((id) => ({ id }));
      this.dialogs.success = true;
    },
    handleFormError() {
      this.$toast.error(
        "El formulario de registro posee errores. Se deben resolver antes de continuar."
      );
    },
    handleBusinessSizeFetch() {
      return PSizeService.getAll();
    },
    handleBusinessTypeFetch() {
      return PCategoryService.getAll();
    },
    handleSubcategoryFetch() {
      return PSubcategoryService.getAllPaginateByCategoryId(
        this.athRegister.categoryId
      );
    },
    handleFormReset() {
      this.athRegister = AthRegisterService.toRegister();
    },
    handleRutBlur() {
      this.handleRutVerify();
      if (this.athRegister.is_provider) {
        this.handleRutSearch();
      }
    },
    async handleRutSearch() {
      this.busy.searching = true;
      if (
        !this.siiProvider.data ||
        this.siiProvider.data.rut !== this.athRegister.rut
      ) {
        this.siiProvider = await PProviderService.search({
          rut: this.athRegister.rut,
        }).catch((e) => e.response);
      }
      this.busy.searching = false;
    },
    async handleRutVerify() {
      this.busy.rut = true;
      await GUserAccountService.verify({ rut: this.athRegister.rut })
        .then((res) => {
          this.rules.rut = !res.data.isRegistered;
        })
        .catch(console.log);
      this.busy.rut = false;
    },
    handleRutRule() {
      return this.rules.rut || "Rut ya registrado";
    },
    async handleEmailBlur(event) {
      this.busy.email = true;
      await GUserAccountService.verify({ email: event.target.value })
        .then((res) => {
          this.rules.email = !res.data.isRegistered;
        })
        .catch(console.log);
      this.busy.email = false;
    },
    handleEmailRule() {
      return this.rules.email || "Email ya registrado";
    },
    descryptIdEvento() {
      this.idEvento = this.$decryptString(this.idEvento);
    },
  },
  created() {
    this.handleFormReset();
    this.descryptIdEvento();
    this.handleRegionFetch();
    this.handleProviderFetch();
    this.handleEventFetch();
    this.handleTypeFetch();
  },
  props: {
    idEvento: {
      type: String,
      required: true,
    },
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
        RulesService.max(255),
      ],
    },
    businessNameRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.max(180)],
    },
    businessSizeRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    businessTypeRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    communeRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    descriptionRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.max(1000)],
    },
    descriptionExpRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.max(2000)],
    },
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.max(80),
      ],
    },
    emailORules: {
      type: Array,
      default: () => [],
    },
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(8),
        RulesService.max(255),
      ],
    },
    nameORules: {
      type: Array,
      default: () => [],
    },
    phoneRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.length(9),
        RulesService.numeric,
      ],
    },
    phoneORules: {
      type: Array,
      default: () => [],
    },
    provinceRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    regionRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    typeRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },
    subcategoryRules: {
      type: Array,
      default: () => [
        RulesService.required,
        // RulesService.rut,
        // RulesService.max(12),
      ],
    },
    webUrlRules: {
      type: Array,
      default: () => [
        // RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  },
  watch: {
    "athRegister.rut"() {
      this.rules.rut = true;
    },
    "athRegister.email"() {
      this.rules.email = true;
    },
    async "athRegisterA.regionId"(regionId) {
      if (regionId) {
        this.busy.province = true;
        GProvinceService.getAllPaginateByRegionId(regionId).then((res) => {
          this.provinces = [];
          this.gProvinces = res.data;
        });
        if (regionId != 5) {
          this.busy.provinceC = true;
          regionId = 5;
          GProvinceService.getAllPaginateByRegionId(regionId).then((res) => {
            this.provincesC = [];
            this.gProvincesC = res.data;
          });
          this.busy.provinceC = false;
        }
        this.busy.province = false;
      }
    },
    async "athRegisterA.provinceId"(provinceId) {
      if (provinceId) {
        this.busy.commune = true;
        GCommuneService.getAllPaginateByProvinceId(provinceId).then((res) => {
          this.communes = [];
          this.gCommunes = res.data;
        });
        this.busy.commune = false;
      }
    },
    gProvinces() {
      this.athRegister.provinceId = null;
      this.athRegister.communeId = null;
      this.getRef("provinceId").refresh();
    },
    gCommunes() {
      this.athRegister.communeId = null;
      this.getRef("communeId").refresh();
    },
  },
};
