// required modules
import Model from '@/models/Model'
import GCommune from '@/models/General/GCommune'

/**
 * This class describes a GProvince model.
 *
 * @class GProvince
 */
export default class GProvinceModel extends Model {

  /**
   * 
   */
  getCommunes() {
    return this.map('communes', GCommune)
  }

  /**
   * Define the GProvince resource.
   *
   * @return {String} GProvince endpoint name.
   */
  static resource() {
    return 'province'
  }
}