//
import axios from 'axios'
import GZoomService from '@/services/api/General/GZoom'

//
export default {
  name: 'v-view-home',
  data() {
    return {
      busy: {
        zoom: false,
      },
      gZoom: {},
      result: 'Validando la autentificacion con ZOOM.',
      error: false,
      success: false,
    }
  },
  methods: {

    /**
     * 
     */
    getZoom() {
      this.gZoom = GZoomService.shape({
        authToken: this.$route.query.state,
        code: this.$route.query.code,
        // redirectUri: 'https://eindustrial.web.sys.dev.innlab.cl/zoom-access',
        redirectUri: 'https://eindustrial.api.sys.dev.innlab.cl/api/zoom-access',
      })
    },

    /**
     * 
     */
    async getZoomAccess() {
      this.busy.zoom = true

      await axios.request({
        method: 'post',
        url: `https://zoom.us/oauth/token?grant_type=authorization_code&code=${ this.$route.query.code }&redirect_uri=https://eindustrial.web.sys.dev.innlab.cl/zoom-redirect`,
        headers: {
          authorization: 'Basic aERhNUNZaUxTMkM2dkFXQjNlaGxmZzpNaU1wdzZRclRCZVBSWUpQWGVhT3VjelExV0I5ZG9vSg==',
        },
      })
      .then(e => console.log(e))
      .catch(e => console.log(e))


      await GZoomService.access(this.gZoom).then(this.handleSuccess).catch(this.handleError)
      this.busy.zoom = false
    },

    /**
     * 
     */
    handleError() {
      this.result = 'No se pudo conectar con Zoom.'
      this.error = true
      this.success = false
    },

    /**
     * 
     */
    handleSuccess() {
      this.result = 'Conexión con Zoom exitosa.'
      this.error = false
      this.success = true
    },
  },
  mounted() {
    this.getZoom()
    this.getZoomAccess()
  },
}