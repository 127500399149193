//
import AppLayoutRouter from '@/layouts/router'

//
export default {
  path: '/auth',
  component: AppLayoutRouter,
  children: [
    {
      path: '/',
      name: 'auth-login',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/AthLogin/login'),
    },
    {
      path: 'registrarse',
      name: 'auth-register',
      component: () => import(/* webpackChunkName: "auth-register" */ '@/views/Auth/AthRegister/register'),
    },
    {
      path: 'recuperar/:token',
      name: 'auth-recovery',
      component: () => import(/* webpackChunkName: "auth-recovery" */ '@/views/General/GUserAccount/recovery'),
    },
    {
      path: 'restablecer',
      name: 'auth-reset',
      component: () => import(/* webpackChunkName: "auth-reset" */ '@/views/General/GUserAccount/reset'),
    },
  ],
}